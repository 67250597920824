<template>
  <GMapMap
    ref="mapRef"
    :center="center"
    :zoom="zoom"
    :map-type-id="mapType"
    :options="{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false
    }"
    class="max-lg:container-extend grid grid-cols-1 grid-rows-1 max-lg:max-h-screen max-lg:min-h-[400px]"
  >
    <UiMapsCluster>
      <slot
        v-for="marker in markers"
        :marker="marker"
      />
    </UiMapsCluster>
  </GMapMap>
</template>

<script setup lang="ts" generic="T extends {latitude: number, longitude: number}">
const props = withDefaults(defineProps<{
  center?: {
    lat: number
    lng: number
  }
  markers: T[]
  centerBounds?: boolean
  zoom?: number
  mapType?: string
}>(), {
  center: undefined,
  mapType: 'terrain',
  zoom: 12,
});

defineSlots<{
  default(props: {marker: T}): void
}>();

const emit = defineEmits<{
  map: [unknown]
}>();

const mapRef = ref();

watchEffect(() => {
  if (mapRef.value) {
    emit('map', mapRef.value);
  }
});

if (process.client) {
  // Center map around the markers
  watchEffect(async () => {
    if (!mapRef.value || !props.markers.length || !props.centerBounds) {
      return;
    }

    const google = await mapRef.value.$gmapApiPromiseLazy();

    const bounds = new google.maps.LatLngBounds();

    for (const marker of props.markers) {
      bounds.extend(
        new google.maps.LatLng(marker.latitude, marker.longitude),
      );
    }

    mapRef.value?.fitBounds(bounds);
  });
}
</script>
