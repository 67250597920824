<template>
  <GMapCluster
    :renderer="clusterRenderer"
  >
    <slot />
  </GMapCluster>
</template>

<script setup lang="ts">
import type {Renderer} from '@googlemaps/markerclusterer';

const clusterRenderer: Renderer = {
  render: ({count, position}: {count: number, position: google.maps.LatLng}) => {
    const color = '#000000';

    const svg = window.btoa(`<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
        <circle cx="120" cy="120" opacity=".6" r="70" />
        <circle cx="120" cy="120" opacity=".3" r="90" />
        <circle cx="120" cy="120" opacity=".2" r="110" />
        <circle cx="120" cy="120" opacity=".1" r="130" />
      </svg>`);

    return new google.maps.Marker({
      position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new google.maps.Size(45, 45),
      },
      label: {
        text: String(count),
        color: 'rgba(255,255,255,0.9)',
        fontSize: '12px',
      },
      zIndex: 1000 + count,
    });
  },
};
</script>
